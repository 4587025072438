import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx, Button } from "theme-ui";

const ThisPage = ({ location }) => {
  const inviteChild = (
    <>
      <h2 sx={{ marginTop: ["30px", "40px"] }}>
        <span>Memorial Details</span>
      </h2>
      <p>
        We celebrated Ed Stillman’s life on December 30, 2020 in a virtual
        gathering and were so thankful to be able to have this celebration
        virtually and to hear from many whose lives he touched. We would love to
        continue hearing your stories and celebrating him in the virtual guest
        book below the recording of our live memorial service.
      </p>
      <a
        href="/images/people/Ed-Stillman-Program.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <Button>View Program</Button>
      </a>
    </>
  );

  const imgUrl = "/images/people/ed-stillman.jpg";
  const fullName = "Edward Harold Stillman";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = "February 8, 1936";
  const deathDate = "December 5, 2020";
  const birthDeathFooter = "84 years old";
  const memorialDate = ""; // "Wednesday December 30th, 2020";
  const memorialTime = ""; // "3pm PST";
  const inviteText = "";
  const optInviteChild = inviteChild;
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fjdx5koa2";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rpmk8kzjuv1fxivfh2ydjvp5ttpftgkzf03au9ub";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/EdStillman.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={optInviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    ></StoryPage>
  );
};

export default ThisPage;
